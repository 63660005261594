import { GENERIC_ERROR, SHOW_NOTIFICATION_STATUS } from "../../constants/enums";
import { CERBERUS_STATUS_CODES } from "../../constants/commonConstants";
import { showNotification } from "../../util/utility";
import { createNewRefund, deleteRefund, fetchLenders, fetchRefunds, sendRefundReminder, updateRefund } from "./RefundsContainer";
import { CreateNewRefundRequestData, GetRefundsParams } from "../../modules/Admin/pages/RecurRefundsPage/hooks/useRecurRefunds";
import { UpdateRefundDataParams } from "../../modules/Admin/pages/RecurRefundsPage/components/RecurRefundsTableRow/RecurRefundsTableRow";

export const getRefunds = async ({
    statuses,
    companyName,
    page,
    size,
    sortBy
}: GetRefundsParams): Promise<any> => {
    try {
        const filters = {
            statusList: statuses?.length && statuses.length > 0 ? statuses : undefined,
            investeeOrganizationName: companyName || undefined,
            page,
            size,
            sortBy
        };

        const response = await fetchRefunds(filters);
        if (response?.data?.responseData?.responseCode === CERBERUS_STATUS_CODES.SUCCESS) {
            return response?.data?.refundRequestsData;
        }
        return [];
    } catch (err: any) {
        const message = "Error fetching refunds";
        showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
        return [];
    }
};

export const updateRefundData = async ( data: UpdateRefundDataParams ): Promise<boolean> => {
    try {
        const response = await updateRefund(data.refundId, {
            status: data?.status,
            updatedBy: data?.updatedBy,
            transactionDate: data?.transactionDate,
            transactionReferenceId: data?.transactionReferenceId,
            transactionReferenceIdentifier: data?.transactionReferenceIdentifier
        });

        if (response?.data?.responseData?.responseCode === CERBERUS_STATUS_CODES.SUCCESS) {
            showNotification(SHOW_NOTIFICATION_STATUS.SUCCESS, 'Refund updated successfully');
            return true;
        } else {
            const message = response?.data?.responseData?.responseMessage ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
            return false;
        }
    } catch (err: any) {
        const message = err?.response?.data?.responseMessage ?? GENERIC_ERROR;
        showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
        return false;
    }
};

export const getLenders = async (investeeOrgId: string) => {
    try {
        const response = await fetchLenders(investeeOrgId);
        if (response?.data?.responseData?.responseCode === CERBERUS_STATUS_CODES.SUCCESS) {
            return response?.data?.investorOrganizationNamesList;
        }
        return [];
    } catch (err: any) {
        const message = err?.response?.data?.responseMessage ?? GENERIC_ERROR;
        showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
        return [];
    }
};

export const createNewRefundRequest = async (data: CreateNewRefundRequestData) => {
    try {
        const response = await createNewRefund(data);
        if (response?.data?.responseData?.responseCode === CERBERUS_STATUS_CODES.SUCCESS) {
            showNotification(SHOW_NOTIFICATION_STATUS.SUCCESS, 'Refund request created successfully');
            return true;
        } else {
            const message = response?.data?.responseData?.responseMessage ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
            return false;
        }
    } catch (err: any) {
        const message = err?.response?.data?.responseMessage ?? GENERIC_ERROR;
        showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
        return false;
    }
};

export const deleteRefundRequest = async (
  refundIds: string[],
  userId: string
) => {
  try {
    const response = await deleteRefund(refundIds, userId);

    if (
      response?.data?.responseData?.responseCode ===
      CERBERUS_STATUS_CODES.SUCCESS
    ) {
      showNotification(
        SHOW_NOTIFICATION_STATUS.SUCCESS,
        "Refund deleted successfully"
      );
      return true;
    } else {
      const message =
        response?.data?.responseData?.responseMessage ?? GENERIC_ERROR;
      showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
      return false;
    }
  } catch (err: any) {
    const message = err?.response?.data?.responseMessage ?? GENERIC_ERROR;
    showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
    return false;
  }
};

export const sendReminderRequest = async (refundIds: string[],userType:string) => {
  try {
    const response = await sendRefundReminder(refundIds,userType);

    if (
      response?.data?.responseData?.responseCode ===
      CERBERUS_STATUS_CODES.SUCCESS
    ) {
      showNotification(
        SHOW_NOTIFICATION_STATUS.SUCCESS,
        "Reminder sent successfully"
      );
      return true;
    } else {
      const message =
        response?.data?.responseData?.responseMessage ?? GENERIC_ERROR;
      showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
      return false;
    }
  } catch (err: any) {
    const message = err?.response?.data?.responseMessage ?? GENERIC_ERROR;
    showNotification(SHOW_NOTIFICATION_STATUS.ERROR, message);
    return false;
  }
};

