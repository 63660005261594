import clsx from 'clsx';
import React from 'react';
import { Modal } from 'react-bootstrap';
import styles from './ConfirmDeleteModal.module.scss';

export default function ConfirmDeleteModal({
    show,
    onHide,
    deleteHeadingName,
    deleteName,
    onDelete
}) {
    const { ImgStyle, ButtonContainer, Cancel, Delete, BreakWord } = styles;

    return (
        <Modal
            show={show}
            onHide={onHide}
            className="Trash-modal"
            size={{ width: "446px", height: "265px" }}>
            <div>
                <div class="modal-header">
                    <button
                        onClick={onHide}
                        type="button"
                        class="close ml-auto">
                        <img src="/assets/clear.png" alt="Clear" />
                    </button>
                </div>
                <div class="modal-body">
                    <img
                        className={clsx("i-modal-icon", ImgStyle)}
                        src="/assets/dustbin-red.svg"
                        alt=""
                    />
                    <div className="fs21 fw6 mb-3 clr-red">Delete {deleteHeadingName}</div>
                    <div className={BreakWord}>Are you sure you want to delete {deleteName}?</div>
                    <br />
                    <span className={clsx("dflex", ButtonContainer)}>
                        <button
                            className={clsx("btn6", Cancel)}
                            onClick={onHide}
                        >Cancel</button>
                        <button
                            className={clsx("btn6", Delete)}
                            onClick={() => onDelete?.()}
                        >Delete</button>
                    </span>
                </div>
            </div>
        </Modal>
    );
}
