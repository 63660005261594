import { toast } from "react-toastify";
import React from "react";
import moment from "moment";
import JSEncrypt from "jsencrypt";
import callApi, {
  API_URL,
  MNEMOSYNE_BASE_URL,
  OLYMPUS_BASE_URL,
  UW_API_URL,
} from "./apiCaller";
import { isEmpty, isNil } from "lodash";
import lodashSet from "lodash/set";
import storage from "./storageService";
import { Mixpanel } from "./mixpanel";
import {
  API_STATUS,
  CERBERUS_TOKEN,
  DEFAULT_INVESTOR_FEE,
  DEFAULT_INVESTOR_ROI,
  DOC_SERVICE_RESPONSE,
  GENERIC_ERROR,
  GST_VALUE,
  MAX_MORATORIUM,
  ORG_ID,
  SHOW_NOTIFICATION_STATUS,
  TOAST_TYPES,
  USER_ID,
} from "../constants/enums";
import {
  DEFAULT_MORAT_VAL_MONTHS,
  ID_CONSTANT,
  URL_CONSTANT,
} from "../modules/Admin/pages/CompaniesViewPage/components/CompaniesDetailPage/components/DocumentVault/consts/consts";
import { kycFolderNames } from "../common/ENUM";
import { FL_ICON_NAMES } from "../common/IconNames";
import { REFUND_STATUS_MAP } from "../modules/Admin/pages/RecurRefundsPage/recurRefundsConsts";

export const convertToCR = (amount) => {
  if (!amount) amount = 0;
  const crore = amount / 10000000;
  return crore;
};
export function showNotification(
  type,
  msg,
  autoClose = 5000,
  position = toast.POSITION.BOTTOM_RIGHT
) {
  type = type.toLowerCase();
  const customId = !autoClose ? "uniquest" : "custom-id-yes";
  switch (type) {
    case "success":
      toast.success(
        ({ closeToast }) => (
          <div style={{ position: "relative" }}>
            <i
              style={{
                position: "absolute",
                width: "40px",
                height: "100%",
                top: "-20px",
              }}
            >
              <img
                style={{ marginRight: "8px" }}
                src="/assets/svg/toast_success.svg"
                alt=""
              />
            </i>
            <div className={"toast-font"}>{msg}</div>
          </div>
        ),
        {
          toastId: customId,
          position,
          autoClose,
        }
      );
      return;

    case "warning":
      toast.warning(
        ({ closeToast }) => (
          <div style={{ position: "relative" }}>
            <i
              style={{
                position: "absolute",
                width: "40px",
                height: "100%",
                top: "-5px",
              }}
            >
              <img
                style={{ marginRight: "8px" }}
                src="/assets/svg/toast_warning.svg"
                alt=""
              />
            </i>
            <div className={"toast-font"}>{msg}</div>
          </div>
        ),
        {
          toastId: customId,
          autoClose,
        }
      );
      return;

    case "error":
      toast.error(
        ({ closeToast }) => (
          <div style={{ position: "relative" }}>
            <i
              style={{
                position: "absolute",
                width: "40px",
                height: "100%",
                top: "-5px",
              }}
            >
              <img
                style={{ marginRight: "8px" }}
                src="/assets/svg/toast_error.svg"
                alt=""
              />
            </i>
            <div className={"toast-font"}>{msg}</div>
          </div>
        ),
        {
          toastId: customId,
          position,
        }
      );
      return;
    case "online":
      toast.dismiss();
      toast.success(
        ({ closeToast }) => (
          <div style={{ position: "relative" }}>
            <i
              style={{
                position: "absolute",
                width: "180px",
                height: "100%",
                top: "-5px",
              }}
            >
              <img
                style={{ marginRight: "8px" }}
                src="/assets/svg/toast_success.svg"
                alt=""
              />
            </i>
            <div className={"toast-font"}>{msg}</div>
          </div>
        ),
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          toastId: customId,
        }
      );
      return;
    case "offline":
    case "socket-offline":
      toast.error(
        ({ closeToast }) => (
          <div style={{ position: "relative" }}>
            <i
              style={{
                position: "absolute",
                width: "180px",
                height: "100%",
                top: "-5px",
              }}
            >
              <img
                style={{ marginRight: "8px" }}
                src="/assets/svg/toast_error.svg"
                alt=""
              />
            </i>
            <div className={"toast-font"}>{msg}</div>
          </div>
        ),
        {
          position: "top-center",
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          toastId: customId,
        }
      );
      return;

    default:
      return;
  }
}

export function segregateDocLink(link) {
  // Check if the string contains only digits
  if (/^\d+$/.test(link)) {
    return { type: ID_CONSTANT, link };
  } else {
    return { type: URL_CONSTANT, link };
  }
}
export function customTimeout(ms, callback) {
  return new Promise((resolve) =>
    setTimeout(() => {
      callback && callback();
      resolve();
    }, ms)
  );
}

export const mixPanelTrack = ({
  id,
  setUserObj,
  trackUserObj,
  trackCategoryName,
}) => {
  Mixpanel.identify(id);
  if (setUserObj)
    Mixpanel.people.set({
      ...setUserObj,
      ip_address: window.localStorage.getItem("myIp"),
      $time: moment()
        .utc()
        .utcOffset(330)
        .format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"),
    });
  // event properties should flow to user property
  if (trackUserObj)
    Mixpanel.track(trackCategoryName, {
      ...trackUserObj,
      ip_address: window.localStorage.getItem("myIp"),
      $time: moment()
        .utc()
        .utcOffset(330)
        .format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"),
    });
};

export function validURL(str) {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
}

export const priceFormat = (price, decimal) => {
  return price
    ? Number(Number(price).toFixed(decimal)).toLocaleString("en-IN", {
        minimumFractionDigits: decimal,
      })
    : "0";
};

export const priceUnformat = (price) => {
  if (!price) return 0;
  return Number(price.replaceAll(",", ""));
};

export const startsWith = (str, word) => {
  return str.lastIndexOf(word, 0) === 0;
};

export const endsWith = (str, word) => {
  return str.indexOf(word, str.length - word.length) !== -1;
};

export const removeFirstSlash = (url) => {
  if (startsWith(url, "/")) {
    return url.replace("/", "");
  }
  return url;
};

// eslint-disable-next-line no-extend-native
Number.prototype.toFixedNoRounding = function (n) {
  const reg = new RegExp("^-?\\d+(?:\\.\\d{0," + n + "})?", "g");
  const a =
    this.toString().match(reg) !== null
      ? this.toString().match(reg)[0]
      : this.toString();
  const dot = a.indexOf(".");
  if (dot === -1) {
    // integer, insert decimal dot and pad up zeros
    return a + "." + "0".repeat(n);
  }
  const b = n - (a.length - dot) + 1;
  return b > 0 ? a + "0".repeat(b) : a;
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const renderUnderwriters = (underwriters, investeeDetails) => {
  return underwriters.find(
    (p) => p._id === investeeDetails?.underwriter_id
  )?.agent_name;
}

export function downloadFileUsingFetch(
  url,
  method = "get",
  argHeaders,
  fileName,
  cb,
  underwriting = false,
  input = {},
  olympus = false,
  mnemosyne = false
) {
  let endpoint = "";
  if (olympus) {
    endpoint = `${OLYMPUS_BASE_URL}/${url}`;
  } else if (underwriting) {
    endpoint = `${UW_API_URL}/underwriting/${url}`;
  } else if (mnemosyne) {
    endpoint = `${MNEMOSYNE_BASE_URL}/${url}`;
  } else {
    endpoint = `${API_URL}/api/${url}`;
  }

  let userId = storage.get(USER_ID);
  let orgId = storage.get(ORG_ID);
  let cerberusToken = storage.get(CERBERUS_TOKEN);
  let headers = { ...argHeaders };
  headers["content-type"] = "application/json";
  if (cerberusToken) {
    if (userId) {
      headers["x-user-id"] = userId;
    }
    if (orgId) {
      headers["x-organization-id"] = orgId;
    }
    headers["x-cerberus-token"] = cerberusToken;
  }
  const object = { method, headers };
  if (method === "post") {
    object.body = JSON.stringify(input);
  }
  fetch(endpoint, object)
    .then(async (res) => {
      if (res.status === 200) {
        const contentDispositionData = res.headers.get("Content-Disposition");
        const _filename =
          contentDispositionData &&
          contentDispositionData
            ?.split("filename=")[1]
            ?.replace(/^"(.*)"$/, "$1");
        if (_filename?.length) {
          fileName = _filename;
        }
        return res.blob();
      } else if (res.status === 400) {
        throw new Error("Data not available");
      } else {
        const resObj = await res.json();
        throw new Error(resObj?.data?.message ?? "Some error occured");
      }
    })
    .then((blob) => {
      const newBlob = new Blob([blob]);
      const url = window.URL.createObjectURL(newBlob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      cb && cb();
    })
    .catch((err) => {
      if (err.message) showNotification("Error", err.message);
      else showNotification("Error", "Some error occurred");
      cb && cb();
    });
}

export function downloadFileFromArrayBuffer(
  url,
  method,
  body,
  headers,
  fileName,
  bufferType,
  getBuffer,
  cb,
  errorCb,
  pingReportJson,
  olympus
) {
  callApi(
    url,
    method,
    body,
    headers,
    null,
    null,
    null,
    null,
    null,
    null,
    olympus
  )
    .then((res) => {
      if (res.status === "Success") {
        if (res?.data?.presignedUrl) {
          let link = document.createElement("a");
          link.href = res?.data.presignedUrl;
          link.target = "_blank";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          cb && cb();
        } else {
          const array = new Uint8Array(getBuffer(res.data));
          const blob = new Blob([array], bufferType);
          const win_url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = win_url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          cb && cb();
        }
      } else {
        if (pingReportJson) {
          pingReportJson();
        } else if (errorCb) {
          errorCb(res);
        } else {
          if (res.message) showNotification("Error", res.message);
          else showNotification("Error", "Some error occurred");
          cb && cb();
        }
      }
    })
    .catch((err) => {
      if (err.message) showNotification("Error", err.message);
      else showNotification("Error", "Some error occurred");
      cb && cb();
    });
}

export const dateLastMonth = () => {
  let date = new Date();
  let currentDate = date.getDate();
  date.setDate(1);
  // date.setHours(-1);
  date.setMonth(date.getMonth() - 24);
  date.setDate(currentDate);
  return moment(date).format("YYYY-MM-DD");
};

export function encryptMessage(message) {
  const jsEncrypt = new JSEncrypt();
  jsEncrypt.setPublicKey(process.env.REACT_APP_ENC_PUB_KEY);
  return jsEncrypt.encrypt(message);
}

export function getRoiFromXIRR(XIRR) {
  // formula (((1+XIRR)^(1/12))-1)*12
  return getRoiFromIrr(XIRR).toFixed(2);
}

export const uploadDocumentInS3 = async (
  signApiRoute,
  input,
  file,
  saveApiRoute,
  metaDataInput,
  docLinkKey,
  onUploadSuccess,
  offerId
) => {
  let data = { url: "" };
  try {
    const response = await callApi(signApiRoute, "post", input);
    if (response) {
      data.url = response.file_url;
      const newData = {
        bucket: response.data?.fields.bucket,
        ...response.data?.fields,
        "Content-Type": file.type,
        file,
      };
      const formData = new FormData();
      for (const name in newData) {
        formData.append(name, newData[name]);
      }
      const requestOptions = {
        method: "post",
        body: formData,
      };
      if (response.data?.url) {
        const uploadResponse = await fetch(response.data.url, requestOptions);
        if (uploadResponse && uploadResponse.ok) {
          let obj = { ...metaDataInput };
          lodashSet(obj, docLinkKey, data.url);
          const response2 = await callApi(saveApiRoute, "post", obj);
          if (response2 && response2.status === "Success") {
            onUploadSuccess && onUploadSuccess();
          } else {
            throw new Error("Couldn't update metaData!");
          }
        } else {
          throw new Error("Couldn't receive signed url!");
        }
      }
    }
  } catch (e) {
    console.error("UploadError: ", e);
    if (!offerId)
      showNotification(
        "Error",
        "Please generate offer first for the investor before uploading IR"
      );
    showNotification("Error", "Failed to upload, something went wrong");
    return;
  }
};

export const calcTimeLeftPerc = (time) => {
  const hrs = Number(time.slice(0, 2));
  const mins = Number(time.slice(3, 5));
  const secs = Number(time.slice(6));
  const diff = hrs * 60 * 60 + mins * 60 + secs;
  return (diff * 100) / (24 * 60 * 60);
};

export const calcTimeDiff = (date) => {
  const endDate = new Date(date);
  endDate.setHours(endDate.getHours() + 24);
  // Converting endDate into IST since startDate is in IST
  endDate.setHours(endDate.getHours() + 5);
  endDate.setMinutes(endDate.getMinutes() + 30);
  const startDate = new Date();
  let diff = endDate.getTime() - startDate.getTime();
  const obj = {};
  if (diff <= 0) obj.timeLeft = "00:00:00";
  else {
    const hours = Math.floor(diff / 1000 / 60 / 60);
    diff -= hours * 1000 * 60 * 60;
    const minutes = Math.floor(diff / 1000 / 60);
    diff -= minutes * 1000 * 60;
    const seconds = Math.floor(diff / 1000);
    obj.timeLeft =
      (hours <= 9 ? "0" : "") +
      hours +
      ":" +
      (minutes <= 9 ? "0" : "") +
      minutes +
      ":" +
      (seconds <= 9 ? "0" : "") +
      seconds;
  }
  obj.timeLeftPerc = (diff * 100) / (24 * 60 * 60 * 1000); // calculating and returning percentage
  return obj;
};

export const reduceOneSecond = (date) => {
  if (date) {
    const time = date.split(":");
    let hrs = Number(time[0]);
    let mins = Number(time[1]);
    let secs = Number(time[2]);
    if (secs === 0) {
      if (mins === 0) {
        if (hrs !== 0) {
          mins = 59;
          secs = 59;
          hrs--;
        }
      } else {
        mins--;
        secs = 59;
      }
    } else secs--;
    return (
      (hrs <= 9 ? "0" : "") +
      hrs +
      ":" +
      (mins <= 9 ? "0" : "") +
      mins +
      ":" +
      (secs <= 9 ? "0" : "") +
      secs
    );
  }
};

export const getValueOr_ = (val) => {
  if (isNil(val)) return "_";
  return val;
};

export const getValueWithRsOr_ = (val) => {
  if (isNil(val)) return "_";
  return `₹ ${val}`;
};

export function pluralize(singular, plural, value) {
  if (value <= 1) return `${value} ${singular}`;
  return `${value} ${plural}`;
}

export function updateParentObj({ parentObj, childObj }) {
  // parentObj: main obj with extra entities; childObj: obj with limited entities but with updated values
  // motive is to return the parentObj with updated values which we have in childObj
  const obj = { ...parentObj };
  for (let i in childObj) obj[i] = childObj[i];
  return obj;
}

export const numberOnly = (value) => {
  return value.replace(/[^0-9]/g, "");
};
export function getRolesFromFields(roleObjectMap, fieldType, value) {
  const rolesData = [];
  if (isEmpty(roleObjectMap)) {
    return rolesData;
  }
  Object.keys(roleObjectMap).forEach((key) => {
    if (roleObjectMap[key] && roleObjectMap[key][fieldType] === value) {
      rolesData.push(roleObjectMap[key]);
    }
  });
  return rolesData;
}

/**
 * id: _id of the user obj
 * investors: array of users object
 * @param {*} id
 * @param {[Object]} users
 * @returns
 */
export function getUserNameFromArray(id, users) {
  let userName = "";
  if (!isEmpty(users)) {
    users.forEach((user) => {
      if (id === user._id.toString() && user.contact_name) {
        userName = user.contact_name;
        return;
      }
    });
  }
  return userName;
}

export const exportToCsv = ({ tableName, setLoader, investeeOrgId }) => {
  setLoader(true);
  const headers = { "content-type": "application/json" };
  const input = {};
  input.investee_organization_id = investeeOrgId;
  input.tableName = tableName;
  downloadFileUsingFetch(
    "export-csv/v2",
    "post",
    headers,
    "contracts.csv",
    () => {
      setLoader(false);
    },
    false,
    input
  );
};
export function monthDaysArray(endOfMonth) {
  let temp = 1;
  const array = [];
  while (temp <= endOfMonth) {
    array.push(temp);
    temp++;
  }
  return array;
}

export const removeCommasFromString = (string) => {
  return string && parseFloat(string.replace(/,/g, ""));
};
export function removeSpecificElementFromArray({ array, element }) {
  const index = array.indexOf(element);
  if (index > -1) array.splice(index, 1);
  return array;
}
export function extractFilePath(url) {
  const urlObj = new URL(url);
  return decodeURIComponent(urlObj.pathname.substring(1)); // Remove the leading '/' and decode
}
export function getDetailsFromSignedUrlToUpload(signedUrl) {
  let toGetBucket = signedUrl.split(".s3.");
  let bucketName = toGetBucket[0];
  bucketName = bucketName.replace("https://", "");
  let region = toGetBucket[1];
  const toGetBucketAndRegion = region.split(".amazonaws.");
  region = toGetBucketAndRegion[0];
  let toGetFilePath = signedUrl.split("amazonaws.com/");
  let filePath = toGetFilePath[1];
  toGetFilePath = filePath.split("?");
  filePath = toGetFilePath[0];
  return { bucketName, region, filePath };
}
export function getAmountInLacs(amount) {
  const LAKH = 100_000;
  return Number(amount / LAKH).toFixed(2);
}

export const getIndianFormattedNumber = (number) => {
  const formatter = new Intl.NumberFormat("en-IN");
  return formatter.format(number);
};

export function getPrevious12Months() {
  const today = new Date();
  today.setMonth(today.getMonth());
  let dates = [];
  for (let i = 0; i < 13; i++) {
    const month = today.getMonth();
    const year = today.getFullYear();
    dates.push(`${month + 1 < 10 ? "0" : ""}${month + 1}-${year}`);
    today.setMonth(month - 1);
  }
  return dates;
}

export const formatMonthName = (monthString) => {
  let [month, year] = monthString.split("-");
  return `${monthsEnum[month].name}'${year.replace("20", "")}`;
};

export const monthsEnum = {
  "01": {
    name: "Jan",
    fullName: "January",
  },
  "02": {
    name: "Feb",
    fullName: "February",
  },
  "03": {
    name: "Mar",
    fullName: "March",
  },
  "04": {
    name: "Apr",
    fullName: "April",
  },
  "05": {
    name: "May",
    fullName: "May",
  },
  "06": {
    name: "Jun",
    fullName: "June",
  },
  "07": {
    name: "Jul",
    fullName: "July",
  },
  "08": {
    name: "Aug",
    fullName: "August",
  },
  "09": {
    name: "Sep",
    fullName: "September",
  },
  10: {
    name: "Oct",
    fullName: "October",
  },
  11: {
    name: "Nov",
    fullName: "November",
  },
  12: {
    name: "Dec",
    fullName: "December",
  },
};

export const getRefundStatusKey = (displayValue) => {
  return Object.entries(REFUND_STATUS_MAP).find(([key, value]) => value === displayValue)?.[0];
};

export const isEnvProd = process.env.REACT_APP_ENVIRONMENT === "production";

export const covertToNum = (price) => {
  if (price !== 0 && typeof price === "string")
    return Number(price.split(",").join(""));
  return 0;
};
export const getQuarterStartAndEnd = (date) => {
  // get start and end date of quarter using moment
  const quarterStart = moment(date).startOf("quarter").format("DD-MM-YYYY");
  const quarterEnd = moment(date).endOf("quarter").format("DD-MM-YYYY");
  return { quarterStart, quarterEnd };
};

export const formatDate = (input, divider = "-") => {
  input = input.split("-").join("/");
  let datePart = input.match(/\d+/g),
    year = datePart[0],
    month = datePart[1],
    day = datePart[2];
  return day + divider + month + divider + year;
};

export function convertArrayToEnum(arr = [], property) {
  let arrayToUse = arr ?? [];
  if (!property) return {};
  let obj = {};
  arrayToUse.forEach((item) => {
    obj[item[property]] = item;
  });
  return obj;
}

export function getTimePassed(date) {
  const now = moment();
  const createdDate = moment(date);
  let timePassed;
  timePassed = now.diff(createdDate, "seconds");
  if (timePassed === 0) return "now";
  if (timePassed === 1) return "1 sec";
  if (timePassed < 60) return timePassed + " secs";
  timePassed = now.diff(createdDate, "minutes");
  if (timePassed === 1) return "1 min";
  if (timePassed < 60) return timePassed + " mins";
  timePassed = now.diff(createdDate, "hours");
  if (timePassed === 1) return "1 hr";
  if (timePassed < 24) return timePassed + " hrs";
  timePassed = now.diff(createdDate, "days");
  if (timePassed === 1) return "1 day";
  if (timePassed < 30) return timePassed + " days";
  timePassed = now.diff(createdDate, "weeks");
  if (timePassed === 1) return "1 week";
  if (timePassed < 4) return timePassed + " weeks";
  timePassed = now.diff(createdDate, "months");
  if (timePassed === 1) return "1 month";
  if (timePassed < 12) return timePassed + " months";
  timePassed = now.diff(createdDate, "years");
  if (timePassed === 1) return "1 year";
  return timePassed + " years";
}

export const removeItemFromArray = (arr, val) => {
  const index = arr.indexOf(val);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
};

export const getTranchTenure = (tenure) => {
  if (tenure === "" || tenure === null || tenure === undefined) return "";
  return `${tenure}`;
};

export function getMoratoriumStatus(moratorium_months) {
  const res = {
    moratoriumBool: true,
    moratoriumMonths: DEFAULT_MORAT_VAL_MONTHS,
  };
  if (
    !isNil(moratorium_months) &&
    Number.isInteger(Number(moratorium_months)) &&
    Number(moratorium_months) <= MAX_MORATORIUM &&
    Number(moratorium_months) >= 1
  ) {
    res.moratoriumBool = true;
    res.moratoriumMonths = Number(moratorium_months);
  }
  if (
    !isNil(moratorium_months) &&
    Number.isInteger(Number(moratorium_months)) &&
    Number(moratorium_months) === 0
  ) {
    res.moratoriumBool = false;
    res.moratoriumMonths = 0;
  }
  return res;
}

export function getMoratoriumEnum(moratorium_months) {
  // will take care of null / undefined
  if (
    !isNil(moratorium_months) &&
    Number.isInteger(Number(moratorium_months)) &&
    Number(moratorium_months) <= MAX_MORATORIUM &&
    Number(moratorium_months) >= 1
  ) {
    return Number(moratorium_months);
  }
  if (
    !isNil(moratorium_months) &&
    Number.isInteger(Number(moratorium_months)) &&
    Number(moratorium_months) === 0
  ) {
    return 0;
  }
  return DEFAULT_MORAT_VAL_MONTHS;
}

export function getMoratoriumOptions(maxMoratorium) {
  const options = [];
  for (let i = 0; i <= maxMoratorium; i++) {
    options.push({ value: i, text: `${i} month${i > i ? "s" : ""}` });
  }
  return options;
}

export function roundToN(num, n) {
  return +(Math.round(num + "e+" + n) + "e-" + n);
}
export const generateRequestId = () => {
  return `${moment().valueOf()}`;
};

export const getFileNameFromSignedUrl = (preSignedUrl, folderName) => {
  return decodeURIComponent(preSignedUrl)
    ?.split(folderName + "/")[1]
    .split("?")[0];
};

export async function fetchDocDetails(
  docID,
  folderName = kycFolderNames.DEBT_ADDN_DOCS
) {
  let url;
  const res = await callApi(
    `file/downloadFilePresigned?fileId=${docID}`,
    "post",
    {},
    {},
    false,
    false,
    false,
    false,
    false,
    true
  );
  if (
    res?.responseData?.responseCode === DOC_SERVICE_RESPONSE.SUCCESS &&
    res?.signedUrl
  ) {
    url = res?.signedUrl;
    const fileName = getFileNameFromSignedUrl(url, folderName);
    return { url, fileName };
  }
}
export const downloadFile = async ({ docId, fileName }) => {
  let url;
  const res = await callApi(
    `file/downloadFilePresigned?fileId=${docId}`,
    "post",
    {},
    {},
    false,
    false,
    false,
    false,
    false,
    true
  );
  if (
    res?.responseData?.responseCode === DOC_SERVICE_RESPONSE.SUCCESS &&
    res?.signedUrl
  ) {
    url = res?.signedUrl;
  } else {
    let message = res.responseData?.responseMessage ?? GENERIC_ERROR;
    if (message === "Metadata not found for file")
      message = "Unsigned File Not Present";
    showNotification("Error", message);
    return;
  }

  const response = await fetch(url);
  const blob = await response.blob();
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
};

export const deleteDoc = async ({
  uploadedDoc,
  dataToPost,
  sanctionCompleted,
  apiRoute,
}) => {
  if (sanctionCompleted) return;
  if (isEmpty(uploadedDoc._id || uploadedDoc.url)) return;

  const response = await callApi(
    apiRoute,
    "post",
    dataToPost,
    {},
    false,
    false,
    false,
    false,
    false,
    false,
    true,
    false
  ).then((res) => {
    if (res.responseData.responseCode === 20) {
      showNotification(TOAST_TYPES.SUCCESS, "Document deleted");
      return true;
    }
  });
  return false;
};

export const DSNEEDINFOARRAY = [
  {
    data_type: "",
    data_type_error: false,
    investors: [],
    investor: "",
    description: "",
    allow_document_upload: true,
    allow_text: true,
    scale_ds_request: true,
    mandatory: true,
    addViaDSNeedInfo: true,
  },
  {
    data_type: "",
    data_type_error: false,
    investors: [],
    investor: "",
    description: "",
    allow_document_upload: true,
    allow_text: true,
    scale_ds_request: true,
    mandatory: true,
    addViaDSNeedInfo: true,
  },
  {
    data_type: "",
    data_type_error: false,
    investors: [],
    investor: "",
    description: "",
    allow_document_upload: true,
    allow_text: true,
    scale_ds_request: true,
    mandatory: true,
    addViaDSNeedInfo: true,
  },
  {
    data_type: "",
    data_type_error: false,
    investors: [],
    investor: "",
    description: "",
    allow_document_upload: true,
    allow_text: true,
    scale_ds_request: true,
    mandatory: true,
    addViaDSNeedInfo: true,
  },
  {
    data_type: "",
    data_type_error: false,
    investors: [],
    investor: "",
    description: "",
    allow_document_upload: true,
    allow_text: true,
    scale_ds_request: true,
    mandatory: true,
    addViaDSNeedInfo: true,
  },
];

export const dataTypeOptions = [
  {
    label: "Ageing Details",
    value: "Ageing Details",
    disabled: false,
  },
  {
    label: "Bureau Reports",
    value: "Bureau Reports",
    disabled: false,
  },
  {
    label: "Financial Projections",
    value: "Financial Projections",
    disabled: false,
  },
  {
    label: "MIS & Provisional Financials",
    value: "MIS & Provisional Financials",
    disabled: false,
  },
  {
    label: "Shareholding Pattern",
    value: "Shareholding Pattern",
    disabled: false,
  },
  {
    label: "Audited Financials",
    value: "Audited Financials",
    disabled: false,
  },
  {
    label: "Debt schedule and Sanction Letters",
    value: "Debt schedule and Sanction Letters",
    disabled: false,
  },
  {
    label: "GST Data and Fillings",
    value: "GST Data and Fillings",
    disabled: false,
  },
  {
    label: "Pitch Deck and Other Documents",
    value: "Pitch Deck and Other Documents",
    disabled: false,
  },
  {
    label: "Bank Statements",
    value: "Bank Statements",
    disabled: false,
  },
  {
    label: "Equity Infusion/Equity Raise Docs",
    value: "Equity Infusion/Equity Raise Docs",
    disabled: false,
  },
  {
    label: "Invoicing Data",
    value: "Invoicing Data",
    disabled: false,
  },
  {
    label: "Receivables Ageing",
    value: "Receivables Ageing",
    disabled: false,
  },
];

export const GLOBALNEEDINFOSTATUSMAPPER = {
  Submitted: "RECEIVED",
  NeedInformation: "Need Information",
  Approved: "APPROVED",
  NotApproved: "Not Approved",
};

export const GLOBAL_NEED_INFO_STATUS = {
  INCOMPLETE: "INCOMPLETE",
  APPROVE: "APPROVE",
};

export const filterEPBankAccounts = (bankAccountsArray) => {
  const filteredArray = [];
  if (isNil(bankAccountsArray)) return filteredArray;
  for (const bankAccount of bankAccountsArray) {
    if (!isNil(bankAccount?.escrow_pay_bank_id)) {
      filteredArray.push(bankAccount);
    }
  }
  return filteredArray;
};

export const checkValidBankAccountOnboarding = ({
  bankAccount,
  accountNumber,
}) => {
  let validBankAccount = true;
  if (isNil(bankAccount)) {
    return false;
  }
  if (isNil(accountNumber)) {
    return false;
  }
  if (
    isNil(bankAccount.ifsc) ||
    ["", "", "null", "None"].includes(bankAccount.ifsc)
  ) {
    return false;
  }
  return validBankAccount;
};

export const getRoiFromIrr = (XIRR) => {
  return Number(((Math.pow(1 + XIRR / 100, 1 / 12) - 1) * 12 * 100).toFixed(2));
};

export const getIrrFromRoi = (roi) => {
  return 100 * (Math.pow(roi / 1200 + 1, 12) - 1);
};

export const calculateXNPV = (rate, cashflows, dates) => {
  let result = 0;
  for (let i = 0; i < cashflows.length; i++) {
    const diff = dates[i].diff(dates[0], "days");
    result += cashflows[i] / Math.pow(1 + rate, diff / 365);
  }
  return result;
};

export const calculateTenureXNPV = (irr, maxCustomTenureLimit) => {
  const cashflows = [0];
  const numberOfMonths = maxCustomTenureLimit % 12;
  const monthMap = {
    0: 3,
    1: 7,
    2: 7,
    3: 7,
    4: 7,
    5: 7,
    6: 7,
    7: 7,
    8: 7,
    9: 5,
    10: 5,
    11: 3,
  };
  const startMonth = monthMap[numberOfMonths];
  const today = moment()
    .date(28)
    .month(startMonth - 1)
    .utcOffset(330)
    .startOf("day");
  const dates = [today];

  for (let i = 0; i < maxCustomTenureLimit; i++) {
    const loopToday = moment()
      .date(28)
      .month(startMonth - 1)
      .utcOffset(330)
      .startOf("day");
    dates.push(loopToday.add(i + 1, "M"));
    cashflows.push(100);
  }
  return Number(calculateXNPV(irr / 100, cashflows, dates));
};

export const getMaxTenurePrice = (irr, maxTenureLimit) => {
  const maxTenureXnpv = calculateTenureXNPV(irr, maxTenureLimit);
  return maxTenureXnpv / (maxTenureLimit * 100);
};

export const calculateRevenue = (
  fee,
  tradingLimitInLacs,
  roiPercentage,
  maxTenure,
  lenderProcessingFees
) => {
  const roi = (roiPercentage ?? 0) / 100;
  const feeWithoutGst = getFeeWithoutGst(fee ?? 0);
  const companyRevenue = (feeWithoutGst * (tradingLimitInLacs ?? 0)) / 100;
  const processsingFeeRevenue = ((lenderProcessingFees ?? 0) * (tradingLimitInLacs ?? 0)) / 100;

  const irr = getIrrFromRoi(roiPercentage ?? 0);
  const price = getMaxTenurePrice(irr, maxTenure ?? 0);
  const investorRevenue =
    ((1 - price) *
      (1 - DEFAULT_INVESTOR_ROI / roi) *
      (tradingLimitInLacs ?? 0)) /
    DEFAULT_INVESTOR_FEE;

  const totalRevenue = (companyRevenue + processsingFeeRevenue + investorRevenue).toFixed(2);
  if (Number.isNaN(Number(totalRevenue))) return "0";
  return totalRevenue;
};

export const differenceInPercentage = (initialValue, increasedValue) => {
  if (!Number(initialValue) || !Number(increasedValue) || increasedValue <= 0)
    return "0";
  const difference = ((increasedValue - initialValue) / increasedValue) * 100;
  if (difference >= 0) return `+${difference.toFixed(2)}`;
  return `-${Math.abs(difference).toFixed(2)}`;
};

export const getFeeWithoutGst = (fee) => {
  const feeWithoutGst = fee / GST_VALUE;
  return Number(feeWithoutGst.toFixed(6));
};

export const getAmountInCrorLacs = (amount) => {
  if (amount >= 10000000) {
    return `${(amount / 10000000).toFixed(2)} Cr`;
  }
  return `${(amount / 100000).toFixed(2)} Lacs`;
};

export function getS3IconLink(iconName) {
  if (Object.values(FL_ICON_NAMES).includes(iconName)) {
    return `https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/${iconName}.svg`;
  }
  return `https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/${iconName}.svg`;
}

export function getS3FLIconLink(iconName, type = "svg") {
  return `https://fl-fe-assets.s3.ap-south-1.amazonaws.com/${type}/${iconName}.${type}`;
}

export const getToggleConfigValueForDelayPaid = (repayment) => {
  if (repayment.repaymentAmountToBeDone >= repayment.repayment_remaining) {
    return { toggle: "on", disabled: true };
  } else if (
    repayment.repaymentAmountToBeDone < repayment.repayment_remaining &&
    repayment.repaymentAmountToBeDone >
      repayment.repayment_remaining_without_delay_charges
  ) {
    return { toggle: "off", disabled: false };
  } else {
    return { toggle: "off", disabled: false };
  }
};

export function containsKeys(obj1, obj2) {
  for (let key in obj1) {
    if (obj2.hasOwnProperty(key)) {
      return obj2[key];
    }
  }
  return false;
}

export function getDecimalAndNumberFromInput(amount) {
  return amount.replace(/[^0-9.]/g, "");
}

export function isValidDate(
  date,
  format = "YYYY-MM-DD",
  endDate = moment().endOf("day"),
  type = 'Transaction', 
  yearsAgo = moment().subtract(1, 'year'),
) {
  const formattedDate = moment(date, format, true);
  if (!formattedDate.isValid() || formattedDate.isAfter(endDate) || formattedDate.isBefore(yearsAgo)) {
    showNotification(
      SHOW_NOTIFICATION_STATUS.ERROR,
      `Invalid date. Please enter a correct ${type} date.`
    );
    return false;
  }
  return true;
}
export const convertDateToYYYYMMDDTZ = (date) => {
  const monthMap = {
      Jan: '01', Feb: '02', Mar: '03', Apr: '04', May: '05', Jun: '06',
      Jul: '07', Aug: '08', Sep: '09', Oct: '10', Nov: '11', Dec: '12'
  };

  // Normalize separators and remove time if present
  let normalizedDate = date.split('T')[0].replace(/[\s\/\-]/g, '-');

  let parts = normalizedDate.match(/^(\d{4})-(\d{2})-(\d{2})$/); // yyyy-mm-dd format
  if (parts) {
      return `${parts[1]}-${parts[2]}-${parts[3]}T00:00:00.000Z`;
  }

  parts = normalizedDate.match(/^(\d{2})-(\d{2})-(\d{4})$/); // dd-mm-yyyy format
  if (parts) {
      return `${parts[3]}-${parts[2]}-${parts[1]}T00:00:00.000Z`;
  }

  parts = normalizedDate.match(/^(\d{2})-([a-zA-Z]{3})-(\d{4})$/); // dd-MMM-yyyy format
  if (parts) {
      return `${parts[3]}-${monthMap[parts[2]]}-${parts[1]}T00:00:00.000Z`;
  }

  return null;
};


