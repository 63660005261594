import React, { useEffect, useMemo } from 'react';
import styles from './RecurRefundsPage.module.scss';
import clearIcon from '../../../../assets/clear.svg';
import { Refund, useRecurRefunds } from './hooks/useRecurRefunds.tsx';
import RecurTable from '../../../../components/RecurTable/RecurTable.jsx';
import AdminHead from '../../components/AdminHead.js';
import PageLoader from '../../../../components/loader/PageLoader.js';
import RecurRefundsTableRow from './components/RecurRefundsTableRow/RecurRefundsTableRow.tsx';
import CreateRefundsModal from './components/CreateRefundsModal/CreateRefundsModal.tsx';
import { REFUND_INITIATED, REFUND_STATUS_MAP, SELECTABLE_REFUND_STATUSES } from './recurRefundsConsts.ts';
import { REFUND_PAGE_VIEWED } from '../../../../constants/mixpanel/AdminEvents.ts';
import { Mixpanel } from '../../../../util/mixpanel.js';
import { create, set } from 'lodash';
import ConfirmDeleteModal from '../../../../components/ConfirmDeleteModal/ConfirmDeleteModal.jsx';
import { INVESTOR_USER_TYPE } from '../../../../constants/enums.js';
import RedDeleteIcon from '../../../../assets/Icons/RedDeleteIcon.js';

const RecurRefundsPage = () => {

    const {
        refundsData,
        isLoading,
        isPageLoading,
        filters,
        modalState,
        handleTextSearch,
        handleStatusFilter,
        handleCreateRefund,
        handleModalClose,
        handleModalSubmit,
        filterObjRep,
        clearFilters,
        isEmpty,
        handleScroll,
        isLoadingMore,
        fetchRefundsData,
        userEmail,
        isAllRefundsSelected,
        handleSelectAllRefunds,
        selectedRefunds,
        handleSelectIndividualRefund,
        handleDeleteRefunds,
        handleMultiDeleteClick,
        handleSendReminder,
        handleDeleteConfirmation,
        deleteRefundIds,
        handleDeleteModalClose,
        toggleCreatedAtSort

    } = useRecurRefunds();

    useEffect(() => {
        Mixpanel.track(REFUND_PAGE_VIEWED,{
            User_Email: userEmail
        })
    }, [])

    const isCheckAllRefundsDisabled = () => {
        return refundsData.filter(refund=>SELECTABLE_REFUND_STATUSES.includes(refund.status)).length === 0;
    }

    const getHeaders = () => [
      {
        jsx: 
        <div>
          <input
            type="checkbox"
            onChange={(e) => handleSelectAllRefunds(e.target.checked)}
            checked={isAllRefundsSelected}
            disabled={isCheckAllRefundsDisabled()}
            />
        </div>
      },
      {
        jsx: 
          <span style={{ width: "10%" }}>
            Created At{" "}
            <button onClick={toggleCreatedAtSort} style={{ border: "none", marginLeft: "7px" }}>
              &#8645;
            </button>
          </span>
        ,
      },
      {
        jsx: <span style={{ width: "15px !important" }}>Company Name</span>,
      },
      {
        jsx: <span style={{ width: "15%" }}>Lender Name</span>,
      },
      {
        jsx: <span style={{ width: "10%" }}>Amount</span>,
      },
      {
        jsx: <span style={{ width: "12.5%" }}>Refund Date</span>,
      },
      {
        jsx: <span style={{ width: "10%" }}>UTR</span>,
      },
      {
        jsx: (
          <div style={{ width: "12.5%" }} className={styles.statusHeader}>
            <select
              value={filters.status?.[filters.status.length - 1] || ""}
              onChange={(e) => handleStatusFilter(e.target.value)}
              className={styles.statusFilter}
            >
              <option value="">All</option>
              {Object.values(REFUND_STATUS_MAP).map((status) => (
                <option key={status} value={status}>
                  {status}
                </option>
              ))}
            </select>
          </div>
        ),
      },
      {
        jsx: <span style={{ width: "2.5%" }}></span>,
      },
      
    ];

    const renderRows = (data: any[]) => {
        if (isEmpty(data)) {
            return (
                <tbody>
                    <tr>
                        <td colSpan={9} className={styles.emptyMessage}>No refunds available.</td>
                    </tr>
                </tbody>
            );
        }
        
        return (
            <tbody>
                {data.map((refund, index) => (
                    <RecurRefundsTableRow
                        key={refund.id}
                        data={{
                            id: refund.id,
                            transactionDate: refund.transactionDate ? refund.transactionDate : '-',
                            companyName: refund.investeeOrganizationName,
                            lenderName: refund.investorOrganizationName,
                            amount: refund.amount,
                            utr: refund.transactionReferenceId,
                            status: refund.status,
                            createdAt: refund.createdAt
                        }}
                        index={index}
                        fetchRefundsData={fetchRefundsData}
                        selectedRefunds={selectedRefunds}
                        handleSelectIndividualRefund={handleSelectIndividualRefund}
                        handleDeleteRefunds={handleDeleteRefunds}
                    />
                ))}
                {isLoadingMore && (
                    <tr>
                        <td colSpan={9} style={{ textAlign: 'center', padding: '1rem' }}>
                            Loading more...
                        </td>
                    </tr>
                )}
            </tbody>
        );
    };

    const refundMap = useMemo(
      () =>
        refundsData.reduce((acc: { [key: string]: Refund }, refund) => {
          acc[refund.id] = refund;
          return acc;
        }, {}),
      [refundsData]
    );

    

    const isDeleteButtonDisabled = () => {
        return selectedRefunds.size === 0;
    }

    const isSendReminderButtonDisabled = () => {
      return selectedRefunds.size === 0 || !Array.from(selectedRefunds).every((refundId) => refundMap[refundId]?.status === REFUND_INITIATED);
    }


    const deleteModalHeadingName = () => {
        return deleteRefundIds.length > 1 ? 'refunds' : 'refund';
    }

    const showDeleteModal = () => {
        return deleteRefundIds.length > 0;
    }

    return (
      <div className="main-body">
        {isPageLoading && <PageLoader />}
        <AdminHead />
        <section className={styles.mainBody}>
          <div className={styles.headerSection}>
            <div className={styles.title}>Refunds</div>
          </div>
          <div className="page-head-bottom-border"></div>
          <div>
            <div className={styles.controls}>
              <div className={styles.searchContainer}>
                <span className={styles.searchIcon}>
                  <img alt="" src="../../../../../assets/search.png" />
                </span>
                <input
                  value={filters.companyName}
                  onChange={(e) => handleTextSearch(e.target.value)}
                  type="text"
                  placeholder="Company Name"
                />
              </div>
              <div className={styles.buttonContainer}>
                {selectedRefunds.size > 0 && (
                  <button
                    className={styles.deleteReminderBtn}
                    onClick={handleMultiDeleteClick}
                    disabled={isDeleteButtonDisabled()}
                  >
                    <RedDeleteIcon />
                    Delete
                  </button>
                )}
                <div
                  className={`${
                    isSendReminderButtonDisabled()
                      ? styles.sendReminderContainerInActive
                      : styles.sendReminderContainerActive
                  }`}
                >
                  <button
                    className={`${
                      isSendReminderButtonDisabled()
                        ? styles.sendReminderBtnInactive
                        : styles.sendReminderBtnActive
                    }`}
                    onClick={() =>
                      handleSendReminder(
                        Array.from(selectedRefunds),
                        INVESTOR_USER_TYPE
                      )
                    }
                    disabled={isSendReminderButtonDisabled()}
                  >
                    <img
                      src={
                        isSendReminderButtonDisabled()
                          ? "https://fl-fe-assets.s3.ap-south-1.amazonaws.com/reminder_inactive.svg"
                          : "https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/reminder.svg"
                      }
                      alt="reminder"
                    />
                    Send Reminder{" "}
                    {!isSendReminderButtonDisabled() &&
                      `(${selectedRefunds.size})`}
                  </button>
                  {!isSendReminderButtonDisabled() && (
                    <button
                      onClick={() => handleSelectAllRefunds(false)}
                      className={styles.cancelReminderBtn}
                    >
                      <img
                        src={
                          "https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/Black-Cross.svg"
                        }
                        alt="cross"
                      />
                    </button>
                  )}
                </div>

                <button onClick={handleCreateRefund}>
                  <span>
                    <img
                      src={
                        "https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/plus-white.svg"
                      }
                      alt="plus"
                    />
                  </span>
                  <span>Create Refund</span>
                </button>
              </div>
            </div>
            {filterObjRep() && (
              <div className={styles.filterTags}>
                {filters.companyName && (
                  <span
                    className={styles.filterTag}
                    onClick={(e) => clearFilters(e, false, "companyName")}
                  >
                    {filters.companyName}
                    <img src={clearIcon} alt="clear" />
                  </span>
                )}
                {filters.status?.map((status, index) => (
                  <span
                    key={index}
                    className={styles.filterTag}
                    onClick={(e) => clearFilters(e, false, "status", status)}
                  >
                    {status}
                    <img src={clearIcon} alt="clear" />
                  </span>
                ))}
                {filterObjRep() && (
                  <span
                    className={styles.clearAll}
                    onClick={(e) => clearFilters(e, true)}
                  >
                    Clear All Filter
                  </span>
                )}
              </div>
            )}
            <div className={styles.tableContainer} onScroll={handleScroll}>
              <RecurTable
                headers={getHeaders()}
                renderRows={renderRows(refundsData)}
                changeColorOnHover={false}
                loading={isLoading}
                style={{}}
                headerStyle={{}}
                additionalRef={null}
                onScroll={handleScroll}
              />
            </div>
          </div>
        </section>
        {modalState.isModalOpen && (
          <CreateRefundsModal
            onClose={handleModalClose}
            onSubmit={handleModalSubmit}
          />
        )}
        {showDeleteModal() && (
          <ConfirmDeleteModal
            show={deleteRefundIds.length > 0}
            onHide={handleDeleteModalClose}
            onDelete={handleDeleteConfirmation}
            deleteHeadingName={deleteModalHeadingName()}
            deleteName={deleteModalHeadingName()}
          />
        )}
      </div>
    );
}

export default RecurRefundsPage