import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  downloadFileFromArrayBuffer,
  showNotification
} from "../../../../../../../../../../../util/utility";
import {
  getInvesteesOrganization,
  getKycDocs,
} from "../../../../../../../../../AdminReducers";
import { getObjAndFileName } from "../../../overviewUtility";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import { fetchKycDocs } from "../../../../../../../../../AdminActions";
import moment from "moment";
import callApi from "../../../../../../../../../../../util/apiCaller";
import { API_STATUS, COMPANY_AUTHORIZATION, GENERIC_ERROR, KYC_DOCS } from "../../../../../../../../../../../constants/enums";
import { pingReportRegenerate } from "../../../../../../../../../../../common/ApiHelpers";
import dayjs from "dayjs";

const Item = styled("div")`
  color: ${(props) => (props.status === "pending" ? "#D4D4D4" : "#4C8DFF")};
  cursor: ${(props) => (props.status === "pending" ? "default" : "pointer")};
  font-weight: 300;
  margin-bottom: 5px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;
const Pendingtext = styled("div")`
  color: #d4d4d4;
  font-size: 10px;
  font-weight: 300;
`;
const RegenerateBtn = styled("button")`
  font-size: 8px;
  background-color: #ffffff;
  border: 0.5px solid #4c8dff;
  color: #4c8dff;
  margin-left: 6px;
  border-radius: 2px;
  padding: 0px 4px;
`;

export default function KycDocs({ ...props }) {
  const dispatch = useDispatch();
  const isKyc = props.isKyc;
  const investorDealId = props.investorDealId;
  const investorDealStatus = props.investorDealStatus;
  const investorOrgId = props.investor_organization_id;
  const kycDocs = useSelector((state) => getKycDocs(state));
  const investeeOrganization = useSelector(getInvesteesOrganization);
  const [additionalDocDescription, setAdditionalDocDescription] = useState("");
  const [hasClickedAddAdditionalDocs, setHasClickedAddAdditionalDocs] =
    useState(false);
  const [deletingAdditionalDoc, setDeletingAdditionalDoc] = useState(false);
  const [isDownloadingFile, setIsDownloadingFile] = useState(false);
    

  const {
    beneficiary,
    company_authorisation,
    company_kyc,
    additional_documents,
    coborrowers,
  } = kycDocs;
  
  useEffect(() => {
    if (isKyc) dispatch(fetchKycDocs(investeeOrganization?._id || props.investeeOrganizationId, investorDealId, investorOrgId, false, true));
    else dispatch(fetchKycDocs(investeeOrganization?._id || props.investeeOrganizationId, investorDealId, investorOrgId, true, false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isKyc,investorDealId,investorOrgId,investeeOrganization?._id, props.investeeOrganizationId]);

  const downloadDoc = (letter, fName, bucket, name) => {
    let { obj } = getObjAndFileName(letter?.doc_link);
    obj.bucket = bucket;
    obj.investeeOrgId = investeeOrganization?._id || props.investeeOrganizationId
    const getBuffer = (data) => {
      return data.file.Body.data;
    };
    const pingReportJson = () => {
      downloadFileFromArrayBuffer(
        "download-file",
        "post",
        obj,
        null,
        fName,
        { type: "application/json" },
        getBuffer,
        null,
        (res) => {
          if (
            res &&
            res.data &&
            res.data.message &&
            res.data.message.code === "NoSuchKey"
          ) {
            fetchDecentroData(
              {
                investee_organization_id: investeeOrganization?._id || props.investeeOrganizationId,
                fetchOrgDataAtBE: true,
              },
              obj,
              fName
            );
          } else showNotification("error", "Some error occured");
        }
      );
    }
    if (name === "decentro_response") {
      const { obj: pdfObj } = getObjAndFileName(
        letter?.pdf_link,
      );
      downloadFileFromArrayBuffer(
        "download-file",
        "post",
        pdfObj,
        null,
        fName,
        { type: "application/pdf" },
        getBuffer,
        null,
        '',
        pingReportJson
      );
    } else {
      downloadFileFromArrayBuffer(
        "download-file",
        "post",
        obj,
        null,
        fName,
        { type: "application/pdf" },
        getBuffer
      );
    }
  };

  const fetchDecentroData = (obj, fileObj, fName) => {
    let funcObj = {};
    if (obj) {
      funcObj = { ...obj };
    }
    funcObj.dataNotAvailableOnS3 = true;
    callApi("/validate-cin-gstin", "post", funcObj)
      .then((res) => {
        if (res.status === API_STATUS.SUCCESS) {
          const getBuffer = (data) => {
            return data.file.Body.data;
          };
          downloadFileFromArrayBuffer(
            "download-file",
            "post",
            fileObj,
            null,
            fName,
            { type: "application/json" },
            getBuffer,
            null,
            (res) => {
              if (
                res &&
                res.data &&
                res.data.message==="The specified key does not exist."
              ) {
                fetchDecentroData({
                  investee_organization_id: investeeOrganization?._id || props.investeeOrganizationId,
                  fetchOrgDataAtBE: true,
                });
              } else showNotification("error", "Some error occured");
            }
          );
        } else showNotification("error", "Couldn't fetch data from decentro");
      })
      .catch((err) => showNotification("error", "Some error occured"));
  };

   const handleRegenerate = async (investee_organization_id, item, keyName) => {
    setIsDownloadingFile(true);
    try {
      if (keyName === KYC_DOCS.PING_REPORT) {
        const response = await pingReportRegenerate(investee_organization_id);
        if (response.status === API_STATUS.SUCCESS) {
          item.latestPingReportFetch = response?.data;
        }
      } else {
        await callApi(`/admin/investeeOrganization/${investee_organization_id}/regenerate-audit-trail`, "post",{ investorOrgId })
        .then((res) => {
          if (res.status === API_STATUS.SUCCESS) {
            showNotification("success", "Regenerated successfully");
            item.doc_link = res?.data;
          } else showNotification("error", "Error while regenerating");
        })
        .catch((error) => {
          showNotification("error", "Error while regenerating");
        });
      }
    } catch (error) {
      showNotification("error", "Error while regenerating");
    }
    setIsDownloadingFile(false);
  }

  const renderDownloadIcon = (status) => {
    if (status === "pending")
      return (
        <img
          alt="download"
          src="/assets/grey-download.svg"
          style={{ marginTop: "3px" }}
        />
      );
    return (
      <img
        alt="download"
        src="/assets/blue-download.svg"
        style={{ marginTop: "3px" }}
      />
    );
  };

  const objMapping = {
    certificate_of_incorporation: "Certificate of Incorporation (COI)",
    gst_certificate: "GST Certificate",
    articles_of_association: "AOA",
    memorandum_of_association: "MOA",
    company_pan: "Company PAN",
    // "official_address_proof": "Current Address Proof",
    company_authorization: "BR, List of Directors and Share Holding Pattern",
    board_resolution: 'Board Resolution',
    share_holding_pattern: 'Share Holding Pattern',
    list_of_directors: 'List of Directors',
    audit_trail: "Audit Trail",
    utility_bill: "Utility Bill / Rent Receipt",
    MFA: "Master Facility Agreement",
    Sanction: "Sanction Letter",
    rent_agreement: "Rent Agreement",
    shareholding_pattern: "Shareholding Pattern",
    decentro_response: "Ping Report",
    additional_documents: "Additional Documents",
  };

  const onAdditionalDocsUpload = (e, key) => {
    if (!additionalDocDescription?.trim()) {
      showNotification("error", "Please enter additional doc description");
      e.target.value = null;
      return;
    }
    const file = e.target.files[0];
    const splitter = file.name.split(".");
    const docName = `${investeeOrganization._id}_${moment().format(
      "yyyyMMDDHHmmss"
    )}_${file.name}`;
    const input = {
      ...(!isKyc && {investor_deal_id: investorDealId}),
      _id: investeeOrganization?.investee_onboarded_id,
      fileName: docName,
      file_type: file.type,
      extension: splitter[splitter.length - 1],
      investee_organization_id: investeeOrganization?._id || props.investeeOrganizationId,
    };
    const metaDataInput = isKyc?{
      additional_documents: {
        cp_key_name: "",
        meta_data: {
          doc_link: "",
          doc_name: docName,
        },
        instant_signing : true,
        description: additionalDocDescription?.trim(),
      },
    }:{
        investor_deal_id: investorDealId,
        additional_document: {
            fileDesc: additionalDocDescription?.trim(),
            fileUrl: "",
        }
    };
    // const onUploadSuccess = () => {
    //   e.target.value = null;
    //   setAdditionalDocDescription("");
    //   setHasClickedAddAdditionalDocs(false);
    //   dispatch(fetchKycDocs(investeeOrganization?._id, investorDealId, investorOrgId, !isKyc, isKyc));
    // };
    // uploadDocumentInS3(
    //   isKyc?`investee-kyc-application/s3/sign`:'admin/deals/deal_documents/s3/sign',
    //   input,
    //   file,
    //   isKyc?`investee-kyc-application/${investeeOrganization?.investee_onboarded_id}/metadata-update?purpose=array_update&section=kyc_docs`:`deals/investor_deals/investor_deal_update`,
    //   metaDataInput,
    //   isKyc?'additional_documents.meta_data.doc_link':'additional_document.fileUrl',
    //   onUploadSuccess
    // );
  };

  const deleteAdditionalDocument = async (additionalDocId) => {
    setDeletingAdditionalDoc(true);
    if (isEmpty(additionalDocId)) return;
    const response = await callApi("deals/investor_deals/investor_deal_update", "post", {
      investor_deal_id: investorDealId,
      delete_additional_doc: {
        doc_id: additionalDocId,
      },
    });
    if (response?.status === API_STATUS.SUCCESS) {
      showNotification("success", "Additional document deleted successfully");
      dispatch(fetchKycDocs(investeeOrganization?._id || props.investeeOrganizationId, investorDealId, investorOrgId, !isKyc, isKyc));
    } else {
      showNotification("error", response?.data?.message ?? GENERIC_ERROR);
    }
    setDeletingAdditionalDoc(false);
  };

  const renderCard = (heading, check, items) => {
    return (
      <div>
        <div className="dflexJA kyc-card-heading mb-3">
          <div className="fs12 fw500">{heading}</div>
          {check ? (
            <img alt="done" src="/assets/green-done.svg" />
          ) : (
            <img alt="pending" src="/assets/pending.svg" />
          )}
        </div>
        {items?.map((item, index) => {
          const { name, subParts, key_name, status, coborrowerName, documents, latestPingReportFetch } = item;
          if (!isNil(name)||!isNil(coborrowerName)) {
            return (
              <div className="fs10" key={index}>
                <div style={{ marginBottom: "5px" }} className="fw300">
                  {name?name:coborrowerName}
                </div>
                {subParts?.map((part, idx) => (
                  <div key={idx} className="dflexJA">
                    <Item status={part.status}>
                      <div
                        className="d-flex align-items-start"
                        onClick={() =>
                          downloadDoc(
                            part,
                            `${part.label}_${index}`,
                            key_name
                          )
                        }>
                        {renderDownloadIcon(part.status)}
                        <div className="ml-2">{part.label}</div>
                      </div>
                      {part.status === "pending" && (
                        <Pendingtext>(pending)</Pendingtext>
                      )}
                    </Item>
                  </div>
                ))}
                 {documents?.map((part, idx) => (
                  <div key={idx} className="dflexJA">
                    <Item status={part.status}>
                      <div
                        className="d-flex align-items-start"
                        onClick={() =>
                          downloadDoc(
                            part,
                            `${part.label}_${index}`,
                            key_name
                          )
                        }>
                        {renderDownloadIcon(part.status)}
                        <div className="ml-2">{part.label??part.doc_name}</div>
                      </div>
                      {part.status === "pending" && (
                        <Pendingtext>(pending)</Pendingtext>
                      )}
                    </Item>
                  </div>
                ))}
              </div>
            );
          }
          return (
            <div key={index} className="fs10">
              <Item status={status}>
                <div className="d-flex align-items-start">
                  <div
                    className="d-flex align-items-start"
                    onClick={() =>
                      downloadDoc(
                        item,
                        item?.key_name ? `${item.key_name}_${index}` : item?.doc_name,
                        item?.bucket,
                        key_name
                      )
                    }
                  >
                    {renderDownloadIcon(status)}
                    <div className="ml-2">{key_name ? objMapping[key_name] : item?.doc_name}</div>
                  </div>
                  {/* {heading === "Additional KYC Documents" &&
                    !isKyc &&
                    investorDealStatus !== INVESTOR_DEAL_STATUS.INVESTOR_DEALS_SANCTIONED && (
                      <button
                        className="btn-link ml-24"
                        disabled={deletingAdditionalDoc}
                        onClick={() => deleteAdditionalDocument(item?.doc_id)}
                      >
                        <img src="/assets/clear-red.svg" alt="delete" />
                      </button>
                    )} */}
                </div>
                {(status === "pending" && (objMapping[key_name] !== KYC_DOCS.PING_REPORT && item?.doc_name !== KYC_DOCS.PING_REPORT)) 
                  ? <Pendingtext>(pending)</Pendingtext>
                  : <>
                  <div className="d-flex align-items">
                  <div className="ml-2">{key_name ? objMapping[key_name] === KYC_DOCS.PING_REPORT ? (latestPingReportFetch !== undefined) && (dayjs(latestPingReportFetch).format("MM-YYYY")) : null : null}</div>
                      {
                      (heading === "Company Authorization" || heading === "Company KYC Documents") && 
                        (objMapping[key_name] === COMPANY_AUTHORIZATION.AUDIT_TRAIL || item?.doc_name === COMPANY_AUTHORIZATION.AUDIT_TRAIL || objMapping[key_name] === KYC_DOCS.PING_REPORT || item?.doc_name === KYC_DOCS.PING_REPORT)  && 
                        <RegenerateBtn 
                          disabled = {isDownloadingFile}
                          onClick={() => handleRegenerate(investeeOrganization?._id || props.investeeOrganizationId, item, objMapping[key_name])}>Regenerate</RegenerateBtn>}
                          </div>
                    </>
                }
              </Item>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="modal-body px-4 pb-4 pt-0">
      <div className="row">
        <div className="col-md-6">
          <div className="CardBorder">
            {renderCard(
              "Company KYC Documents",
              company_kyc?.is_kyc_documents_complete,
              company_kyc?.data
            )}
          </div>
          <div className="CardBorder">
            {renderCard(
              "Company Authorization",
              company_authorisation?.is_company_authorization_complete,
              company_authorisation?.data
            )}
          </div>
        </div>
        <div className="col-md-6">
          <div className="CardBorder h-100">
            {renderCard(
              "Beneficial Owner(s) KYC",
              beneficiary?.is_authorized_signatory_complete,
              beneficiary?.data
            )}
          </div>
        </div>
        {!isEmpty(coborrowers?.data)&&<div className="col-md-6 mt-4">
          <div className="CardBorder h-100">
            {renderCard(
              "Co-Borrower(s) KYC",
              coborrowers?.complete,
              coborrowers?.data
            )}
          </div>
        </div>}
        {/* Additional Docs */}
        <div className="col-md-12">
          <div className="CardBorder mt-3" style={{ marginBottom: "30px" }}>
            {renderCard(
              "Additional KYC Documents",
              !isEmpty(additional_documents),
              additional_documents?.data
            )}
            {/* Additional KYC Documents Upload */}
            {/* {hasClickedAddAdditionalDocs && (
              <div className="additional-doc-inputs-wrapper">
                <input
                  className="additional-doc-input-description"
                  type="text"
                  value={additionalDocDescription}
                  placeholder="Enter File Description"
                  onChange={(e) => setAdditionalDocDescription(e.target.value)}
                />
                <label>
                  <input
                    type="file"
                    hidden
                    onChange={(e) =>
                      onAdditionalDocsUpload(e, "additional_documents")
                    }
                  />
                  <div className="add-Additional-docs-upload">
                    <img
                      style={{ width: "10px", height: "10px" }}
                      src="/assets/file-upload.svg"
                      alt=""
                      className="mr-1 ml-1"
                    />
                    <span className="">Upload</span>
                  </div>
                </label>
              </div>
            )} */}
            {/* Add Additional Docs */}
            {/* <div
              className="add-Additional-docs"
              onClick={() => setHasClickedAddAdditionalDocs(true)}>
              <label className="dflex-ac mb-0">
                <img
                  src="/assets/Add-blue.svg"
                  alt="Add"
                  style={{
                    width: "10px",
                    height: "10px",
                    margin: "0 5px",
                  }}
                />
                Additional KYC Documents
              </label>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
