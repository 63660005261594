import React, { useState, useEffect, useRef, useCallback } from 'react';
import styles from './CreateRefundsModal.module.scss';
import { Select } from '../../../../../../common/_custom/Select/Select';
import { Input } from '../../../../../../common/_custom/Input/Input';
import { CreateNewRefundRequestData } from '../../hooks/useRecurRefunds';
import { useCreateRefunds } from '../../hooks/useCreateRefunds';
import { REFUND_STATUS_MAP, UTR } from '../../recurRefundsConsts';

interface Props {
  onClose: () => void;
  onSubmit: (data: CreateNewRefundRequestData) => void;
}

const CreateRefundsModal: React.FC<Props> = ({ onClose, onSubmit }) => {
  const {
    formState,
    isDropdownOpen,
    loading,
    companies,
    lenders,
    isFormValid,
    setDropdownOpen,
    updateFormState,
    handleCompanySelection,
    handleLenderSelection,
    isSubmitting,
    handleSubmit,
    modalRef,
  } = useCreateRefunds(onSubmit, onClose);

  return (
    <div className={styles.modalContainer}>
      <div className={styles.modalContent} ref={modalRef}>
        <div className={styles.modalHeader}>
          <h2>Refunds</h2>
          <img src={"https://fl-fe-assets.s3.ap-south-1.amazonaws.com/svg/close.svg"} alt="close" onClick={onClose} style={{cursor: 'pointer'}} />
        </div>
        <div className={styles.modalBody}>
          <div>
          <div className={styles.inputFieldContainer}>
            <Input
              label="Company Name"
              id="companyName"
              showImg={true}
              handleChange={(value) => updateFormState({ companyName: value })}
              value={formState.companyName}
              onFocus={() => setDropdownOpen(prev => ({ ...prev, company: true }))}
              onBlur={() => {
                const timeoutId = setTimeout(() => {
                  setDropdownOpen(prev => ({ ...prev, company: false }));
                }, 200);
                return () => clearTimeout(timeoutId);
              }}
              inputStyle={{
                borderColor: formState.companyName ? '#4C8DFF' : undefined,
                backgroundColor: formState.companyName ? '#0000' : undefined
              }}
            />
          </div>
          <div className={styles.dropdownContainer}>
            {isDropdownOpen.company && (
              <div>
                {formState?.companyName?.length < 3 ? (
                  <Select
                    isLoading={false}
                    isLoadingText=""
                    notFoundText="Type at least 3 characters"
                    items={[]}
                    handleOnClick={() => {}}
                  />
                ) : (
                  <Select
                    isLoading={loading.companies}
                    isLoadingText="Loading Companies..."
                    notFoundText="No Companies Found"
                    items={companies}
                    handleOnClick={handleCompanySelection}
                  />
                )}
              </div>
            )}
            </div>
          </div>

          <div>
          <div className={styles.inputFieldContainer}>
            <Input
              label="Lender Name"
              id="lenderName"
              showImg={true}
              handleChange={(value) => updateFormState({ lenderName: value })}
              value={formState.lenderName}
              onFocus={() => setDropdownOpen(prev => ({ ...prev, lender: true }))}
              onBlur={() => {
                const timeoutId = setTimeout(() => {
                  setDropdownOpen(prev => ({ ...prev, lender: false }));
                }, 200);
                return () => clearTimeout(timeoutId);
              }}
              isDisabled={!formState.selectedCompanyId}
            />
          </div>
          <div className={styles.dropdownContainer}>
            {isDropdownOpen.lender && (
              <div>
                <Select
                  isLoading={loading.lenders}
                  isLoadingText="Loading Lenders..."
                  notFoundText="No Lenders Found"
                  items={lenders}
                  handleOnClick={handleLenderSelection}
                />
              </div>
            )}
          </div>
          </div>

          <div className={styles.inputFieldContainer}>
              <Input
                label="Amount (INR)"
                id="amount"
                type="number"
                min="0.01"
                value={formState.amount}
                handleChange={(value) => {
                  if (value.includes('.') && value.split('.')[1].length > 2) {
                    return;
                  }
                  
                  const numValue = Number(parseFloat(value).toFixed(2));
                  if (value && numValue > 0) {
                    updateFormState({ amount: numValue });
                  } else {
                    updateFormState({ amount: '' });
                  }
                }}
              />
          </div>

          <div className={styles.twoColumnRow}>
            <div className={styles.inputFieldContainer}>
              <label>Status</label>
              <div className={`${styles.selectContainer} ${formState.status ? styles.hasValue : ''}`}>
                <select
                  value={formState.status}
                  onChange={(e) => updateFormState({ status: e.target.value })}
                  className={styles.input}
                >
                  <option value={REFUND_STATUS_MAP.REFUND_PENDING}>Pending</option>
                  <option value={REFUND_STATUS_MAP.REFUND_INITIATED}>Initiated</option>
                  <option value={REFUND_STATUS_MAP.REFUND_APPROVED}>Approved</option>
                </select>
              </div>
            </div>

            {formState.status === REFUND_STATUS_MAP.REFUND_APPROVED && (
              <div className={styles.inputFieldContainer}>
                <label>Transaction Date</label>
                <div className={`${styles.selectContainer} ${formState.transactionDate ? styles.hasValue : ''}`}>
                  <input
                    type="date"
                    value={formState.transactionDate}
                    onChange={(e) => updateFormState({ transactionDate: e.target.value })}
                    className={styles.input}
                    max={new Date().toISOString().split('T')[0]}
                  />
                </div>
              </div>
            )}
          </div>

          {formState.status === REFUND_STATUS_MAP.REFUND_APPROVED && (
            <div className={styles.halfinputfeildcontainer}>
              <div className={styles.inputFieldContainer}>
                  <Input
                    label={UTR}
                    id="utr"
                    value={formState.utr}
                    handleChange={(value) => updateFormState({ utr: value })}
                    inputStyle={{
                      backgroundColor: formState.utr ? '#0000' : undefined,
                      borderColor: formState.utr ? '#4C8DFF' : undefined
                    }}
                  />
              </div>
            </div>
          )}

          <button
            onClick={handleSubmit}
            className={styles.addButton}
            disabled={!isFormValid || isSubmitting}
          >
            {isSubmitting ? 'Adding...' : 'Add New'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateRefundsModal;
